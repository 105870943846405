<template>
  <div class="contBox">
    <div class="contMask"></div>
    <div class="contVideo">
      <video
          :controls="false"
          autoplay
          loop
          style="width: 100%;height: 100%;"
        >
        <source src="./鸟瞰.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="cont">
      <div class="PageEight">
        <div
          class="point point1"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            animate__flipOutY: !isShow,
          }"
        >
          <div class="part2">HPC算力</div>
        </div>
        <div
          class="point point2 animate__delay1"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__flipOutY animate__delay0': !isShow,
          }"
        >
          <div class="part2">云存储</div>
        </div>
        <div
          class="point point3 animate__delay2"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__flipOutY animate__delay0': !isShow,
          }"
        >
          <div class="part2">云计算</div>
        </div>
        <div
          class="point point4 animate__delay3"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__flipOutY animate__delay0': !isShow,
          }"
        >
          <div class="part2">网络安全</div>
        </div>
        <div
          class="point point5 animate__delay4"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__flipOutY animate__delay0': !isShow,
          }"
        >
          <div class="part2">中间件</div>
        </div>
        <div
          class="point point6 animate__delay5"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__flipOutY animate__delay0': !isShow,
          }"
        >
          <div class="part2">AI数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./index.scss";

export default {
  name: "PageEight",
  data() {
    return {
      isShow: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow = false;
    }, 5000);
  },
  methods: {},
};
</script>