var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"PageSix-cloud page11-1",class:{
      'animate__animated animate__fadeInUp': _vm.isShow,
      animate__flipOutY: !_vm.isShow,
    }},[_c('img',{attrs:{"src":require("../../assets/images/page11/page11-01.png")}})]),(_vm.isShow11)?_c('div',{staticClass:"PageSix-cloud page11-2",class:{
      'animate__animated animate__animated_duration animate__fadeInUp':
        _vm.isShow1,
      animate__flipOutY: !_vm.isShow1,
    }},[_c('img',{attrs:{"src":require("../../assets/images/page11/page11-02.png")}})]):_vm._e(),(_vm.isShow21)?_c('div',{staticClass:"PageSix-cloud page11-3",class:{
      'animate__animated animate__animated_duration animate__fadeInUp':
        _vm.isShow2,
      animate__flipOutY: !_vm.isShow2,
    }},[_c('img',{attrs:{"src":require("../../assets/images/page11/page11-03.png")}})]):_vm._e(),(_vm.isShow31)?_c('div',{staticClass:"PageSix-cloud page11-4",class:{
      'animate__animated animate__animated_duration animate__fadeInUp':
        _vm.isShow3,
      animate__flipOutY: !_vm.isShow3,
    }},[_c('img',{attrs:{"src":require("../../assets/images/page11/page11-04.png")}})]):_vm._e(),(_vm.isShow41)?_c('div',{staticClass:"PageSix-cloud page11-5",class:{
      'animate__animated animate__animated_duration animate__fadeInUp':
        _vm.isShow4,
      animate__flipOutY: !_vm.isShow4,
    }},[_c('img',{attrs:{"src":require("../../assets/images/page11/page11-05.png")}})]):_vm._e(),(_vm.isShow51)?_c('div',{staticClass:"PageSix-cloud page11-6",class:{
      'animate__animated animate__animated_duration animate__fadeIn':
        _vm.isShow51,
      'animate__animated animate__animated_duration animate__slideInDown PageSix-cloud-animate':
        _vm.isShow5,
    }},[_c('img',{attrs:{"src":require("../../assets/images/page11/logo.png")}})]):_vm._e(),_c('video',{attrs:{"controls":false,"autoplay":""}},[_c('source',{attrs:{"src":"http://rwx3y0ja5.hb-bkt.clouddn.com/page11-%E7%89%87%E5%B0%BE.mp4?e=1688103624&token=fji8x5q1di6bLpzZ1s_Cn5Zt09sPWnoQhXeWK3Ku:z4Gar3H9MXhxS8Gh2sKaegxXckI=","type":"video/mp4"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }