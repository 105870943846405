<template>
  <div class="PageSeven">
    <div class="contMask"></div>
    <div class="content">
      <div
        class="cloud cloud1 animate__animated"
        :class="{ animate__fadeIn: isShow, animate__fadeOut: !isShow }"
      >
        <div class="imgPart1">{{ showText1 }}</div>
        <div class="imgPart2">{{ showText2 }}</div>
        <div class="imgPart3">{{ showText3 }}</div>
      </div>
      <div
        class="cloud cloud2 animate__animated"
        style="padding: 66px 43px 36px 33px"
        :class="{ animate__fadeIn: isShow, animate__fadeOut: !isShow }"
      >
        <video :controls="false" autoplay>
          <source src="./page8.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        class="cloud cloud3 animate__animated"
        :class="{ animate__fadeIn: isShow, animate__fadeOut: !isShow }"
      >
        <div class="part1">
          <img src="@/assets/images/page7/robot.png" />
        </div>
        <div class="part2">
          <div class="list">提高服务质量</div>
          <div class="list">降低企业成本</div>
          <div class="list">实现企业数字化</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "PageSeven",
  components: {},
  data() {
    return {
      timer1: null,
      showText1: "",
      timer2: null,
      showText2: "",
      timer3: null,
      showText3: "",
      isShow: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.init(
        "工单服务管理系统以服务为导向，关注用户的核心需求与期望，通过建立服务目录、服务水平协议等服务管理流程和规范，提高服务质量和效率，提升客户满意度",
        "showText1",
        "timer1"
      );
    }, 0);

    setTimeout(() => {
      this.init(
        "对IT服务进行全面的管理和监控，包括服务请求、事件管理、问题管理、变更管理、配置管理等方面，通过建立完善的服务管理体系，提高服务管理的效率和可靠性",
        "showText2",
        "timer2"
      );
    }, 3800);

    setTimeout(() => {
      this.init(
        "优势：提高服务质量和效率、降低成本和风险、保障用户满意度、实现企业数字化转型；",
        "showText3",
        "timer3"
      );
    }, 8000);
    setTimeout(() => {
      this.isShow = false;
    }, 10000);
  },
  methods: {
    init(txt, showText, time) {
      const _this = this;

      this[showText] = "";
      clearTimeout(this[time]);

      var speed = 40; //设置定时的速度 越来越快
      var count = 1;

      function changeContent() {
        _this[showText] = txt.substring(0, count); //截取字符串
        count++;

        if (count != txt.length + 1) {
          // speed -= 1;
          // if (speed < 5) speed = 5;

          _this[time] = setTimeout(changeContent, speed);
        }
      }
      changeContent();
    },
  },
};
</script>