<template>
  <div class="service db vh">
    <div class="fx1 animate__animated"
      :class="{ 'animate__fadeInUp': isShow, 'animate__fadeOut animate__delay0': !isShow }">
      <h6>企业用户自主选型</h6>
      <div class="service-cont">
        <dl>
          <dt><img src="../../assets/images/page10/img-1.png" width="115" /></dt>
          <dd>全栈服务</dd>
          <dd>自主搭配</dd>
          <dd>快速响应</dd>
          <dd>一键申请</dd>
        </dl>
      </div>
    </div>
    <div class="fx1 pr animate__animated"
      :class="{ 'animate__fadeInUp': isShow, 'animate__fadeOut': !isShow }">
      <p class="service-til service-til-l">用户自助下单平台智能处理工单</p>
      <p class="service-til service-til-r">云服务商一键开通资源</p>
      <h6>运营后台高效派单</h6>
      <div class="service-cont">
        <dl>
          <dt><img src="../../assets/images/page10/img-2.png" width="115" /></dt>
          <dd>合理分析</dd>
          <dd>高效派单</dd>
          <dd>降本增效</dd>
          <dd>引领产业转型</dd>
        </dl>
      </div>
    </div>
    <div class="fx1 animate__animated"
      :class="{ 'animate__fadeInUp': isShow, 'animate__fadeOut': !isShow }">
      <h6>企业用户自主选型</h6>
      <div class="service-cont">
        <dl>
          <dt><img src="../../assets/images/page10/img-3.png" width="115" /></dt>
          <dd>加速效率</dd>
          <dd>业务高可用</dd>
          <dd>服务可靠</dd>
          <dd>性能优化</dd>
        </dl>
      </div>
    </div>
    <dv-flyline-chart-enhanced 
      :config="config" 
      style="position: absolute;width:100%;height:100%;"
      :class="{ 'animate__animated animate__fadeIn': isShow, 'animate__animated animate__fadeOut': !isShow }"
    />
    <dv-flyline-chart-enhanced 
      :config="config1" 
      :dev="true" 
      style="position: absolute;width:100%;height:100%;" 
      :class="{ 'animate__animated animate__fadeIn': isShow, 'animate__animated animate__fadeOut': !isShow }"
    />
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "PageTen",
  data() {
    return {
      isShow: true,
      timer1: null,
      showText1: '',
      timer2: null,
      showText2: '',
      config: {
        curvature: 6,
        points: [{
          name: 'point1',
          coordinate: [0.16, 0.37]
        }, {
          name: 'point2',
          coordinate: [0.35, 0.36]
        }],
        lines: [{
          source: 'point1',
          target: 'point2'
        }],
        line: {
          color: '#fff',
          width: 5,
          duration: [20, 30],
        },
        halo: {
          show: false
        },
      },
      config1: {
        curvature: 6,
        points: [{
          name: 'point1',
          coordinate: [0.65, 0.37]
        }, {
          name: 'point2',
          coordinate: [0.46, 0.36]
        }],
        lines: [{
          source: 'point2',
          target: 'point1'
        }],
        line: {
          color: '#fff',
          width: 5,
          duration: [20, 30],
        },
        halo: {
          show: false
        },
      }
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow = false;
    }, 5000)
    this.init();
  },
  methods: {
    init() {
      this.handleTxt('用户自助下单平台智能处理工单', '1');
      this.handleTxt('云服务商一键开通资源', '2');
    },
    handleTxt(content, tag) {
      const _this = this;
      const showTextTag = 'showText' + tag;
      const timerTag = 'timer' + tag;
      this[showTextTag] = "";
      clearTimeout(this[timerTag]);

      var speed = 90; //设置定时的速度 越来越快
      var count = 1;

      function changeContent() {
        _this[showTextTag] = content.substring(0, count); //截取字符串
        count++;

        if (count != content.length + 1) {
          _this[timerTag] = setTimeout(changeContent, speed);
        }
      }
      changeContent();
    }
  },
};
</script>
