var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{class:_vm.current === 'resource'
        ? 'home home3'
        : _vm.current === 'Page8' || _vm.current === 'Page9' || _vm.current === 'Page10'
        ? 'home home4'
        : _vm.current === 'Page12' || _vm.current === 'Page4'? 'home home1' : 'home'},[_c('div',{staticClass:"title"}),_c(_vm.current,{tag:"component",staticStyle:{"z-index":"2"}}),_c('div',{staticClass:"footer",class:_vm.current === 'resource'
          ? 'footer1'
          : _vm.current === 'Page14'
          ? 'footer4'
          : _vm.current === 'Page13'
          ? 'footer3'
          : 'footer2'},[_c('span',{staticClass:"class1",on:{"click":function($event){return _vm.toHome()}}},[_vm._v("运营管理")]),_c('span',{staticClass:"class2",on:{"click":function($event){return _vm.toResource()}}},[_vm._v("资源管理")]),_c('span',{staticClass:"class3",on:{"click":function($event){return _vm.toBussiness()}}},[_vm._v("业务管理")]),_c('span',{staticClass:"class4",on:{"click":function($event){return _vm.toJifang()}}},[_vm._v("机房管理")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }