<template>
  <div class="container">
    <div
      :class="
        current === 'resource'
          ? 'home home3'
          : current === 'Page8' || current === 'Page9' || current === 'Page10'
          ? 'home home4'
          : current === 'Page12' || current === 'Page4'? 'home home1' : 'home'
      "
    >
      <div class="title">
        <!-- <div class="playButton" @click="playVideo">
          <img src="@/assets/images/home/icon-airplay.png" />{{
            isPlay ? "暂停" : "演示"
          }}
        </div> -->
      </div>
      <component :is="current" style="z-index: 2"></component>
      <div
        class="footer"
        :class="
          current === 'resource'
            ? 'footer1'
            : current === 'Page14'
            ? 'footer4'
            : current === 'Page13'
            ? 'footer3'
            : 'footer2'
        "
      >
        <span class="class1" @click="toHome()">运营管理</span>
        <span class="class2" @click="toResource()">资源管理</span>
        <span class="class3" @click="toBussiness()">业务管理</span>
        <span class="class4" @click="toJifang()">机房管理</span>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
import Page1 from "../About";
import Page2 from "../page2";
import Page3 from "../page3";
import Page4 from "../Page4";
import Page5 from "../page5";
import Page6 from "../page6";
import Page7 from "../page8";
import Page8 from "../page7";
import Page9 from "../page9";
import Page10 from "../Page10";
import Page11 from "../page11";
import Page12 from "../page12";
import Page13 from "../page13";
import Page14 from "../page14";
import resource from "../resource";

export default {
  name: "HomePage",
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
    resource,
    Page13,
    Page14,
  },
  data() {
    return {
      current: "Page12",
      isPlay: false,
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      timer5: null,
      timer6: null,
      timer7: null,
      timer8: null,
      timer9: null,
      timer10: null,
      timer11: null,
    };
  },
  methods: {
    toJifang() {
      this.isPlay = false;
      this.clearTimer();
      this.current = "Page14";
    },
    toBussiness() {
      this.isPlay = false;
      this.clearTimer();
      this.current = "Page13";
    },
    toHome() {
      this.isPlay = false;
      this.clearTimer();
      this.current = "Page12";
    },
    toResource() {
      this.isPlay = false;
      this.clearTimer();
      this.current = "resource";
    },
    playVideo() {
      this.clearTimer();
      if (this.isPlay) {
        this.isPlay = false;
        this.current = "Page12";
      } else {
        this.isPlay = true;
        this.play();
      }
    },
    play() {
      this.current = "Page1";
      this.timer1 = setTimeout(() => {
        if(this.isPlay) this.current = "Page2";
      }, 10000);
      this.timer2 = setTimeout(() => {
        if(this.isPlay) this.current = "Page3";
      }, 19000);
      this.timer3 = setTimeout(() => {
        if(this.isPlay) this.current = "Page4";
      }, 29000);
      this.timer4 = setTimeout(() => {
        if(this.isPlay) this.current = "Page5";
      }, 37000);
      this.timer5 = setTimeout(() => {
        if(this.isPlay) this.current = "Page6";
      }, 44000);
      this.timer6 = setTimeout(() => {
        if(this.isPlay) this.current = "Page7";
      }, 54000);
      this.timer7 = setTimeout(() => {
        if(this.isPlay) this.current = "Page8";
      }, 61000);
      this.timer8 = setTimeout(() => {
        if(this.isPlay) this.current = "Page9";
      }, 73000);
      this.timer9 = setTimeout(() => {
        if(this.isPlay) this.current = "Page10";
      }, 80000);
      this.timer10 = setTimeout(() => {
        if(this.isPlay) this.current = "Page11";
      }, 87000);
      this.timer11 = setTimeout(() => {
        if(this.isPlay) this.play()
      }, 102000);
    },
    clearTimer(){
      clearTimeout(this.timer1);
      clearTimeout(this.timer2);
      clearTimeout(this.timer3);
      clearTimeout(this.timer4);
      clearTimeout(this.timer5);
      clearTimeout(this.timer6);
      clearTimeout(this.timer7);
      clearTimeout(this.timer8);
      clearTimeout(this.timer9);
      clearTimeout(this.timer10);
      clearTimeout(this.timer11);
    }
  },
};
</script>
s