<template>
  <div class="contBox">
    <div class="contMask"></div>
    <div class="contVideo">
      <video
          :controls="false"
          autoplay
          loop
          style="width: 100%;height: 100%;"
        >
        <source src="./顶部效果.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="cont">
      <div class="PageFive">
        <div
          class="cloud1"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__delay0 animate__flipOutY': !isShow,
          }"
          style="z-index: 2"
        >
          <div class="part1" style="z-index: 2">
            <div class="text" style="z-index: 2">市人大常委会办公室</div>
            <div class="imgPart" style="z-index: 2">
              <img src="@/assets/images/page4/img1.jpg" />
            </div>
          </div>
          <div class="part2" style="z-index: 2"></div>
        </div>
        <div
          class="cloud2 animate__delay1"
          style="z-index: 2"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__delay0 animate__flipOutY': !isShow,
          }"
        >
          <div class="part1" style="z-index: 2">
            <div class="text" style="z-index: 2">网站新媒体部</div>
            <div class="imgPart" style="z-index: 2">
              <img src="@/assets/images/page4/img1.jpg" />
            </div>
          </div>
          <div class="part2" style="z-index: 2"></div>
        </div>
        <div
          class="cloud3 animate__delay2"
          style="z-index: 2"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__delay0 animate__flipOutY': !isShow,
          }"
        >
          <div class="part1" style="z-index: 2">
            <div class="text" style="z-index: 2">市科技局</div>
            <div class="imgPart" style="z-index: 2">
              <img src="@/assets/images/page4/img1.jpg" />
            </div>
          </div>
          <div class="part2" style="z-index: 2"></div>
        </div>
        <div
          class="cloud4 animate__delay3"
          style="z-index: 2"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__delay0 animate__flipOutY': !isShow,
          }"
        >
          <div class="part1" style="z-index: 2">
            <div class="text" style="z-index: 2">市信访局</div>
            <div class="imgPart" style="z-index: 2">
              <img src="@/assets/images/page4/img1.jpg" />
            </div>
          </div>
          <div
            class="part2 animate__fadeInUp animate__animated"
            style="z-index: 2"
          ></div>
        </div>
        <div
          class="cloud5 animate__delay4"
          style="z-index: 2"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            'animate__delay0 animate__flipOutY': !isShow,
          }"
        >
          <div class="part1" style="z-index: 2">
            <div class="text" style="z-index: 2">市社科联</div>
            <div class="imgPart" style="z-index: 2">
              <img src="@/assets/images/page4/img1.jpg" />
            </div>
          </div>
          <div class="part2" style="z-index: 2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "PageThree",
  components: {},
  data() {
    return {
      isShow: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow = false;
    }, 5000);
  },
  methods: {},
};
</script>