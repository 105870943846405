<template>
  <div class="contBox">
    <div class="contMask"></div>
    <!-- <div class="contVideo">
      <video
          :controls="false"
          autoplay
          loop
          style="width: 100%;height: 100%;"
        >
          <source src="./鸟瞰效果倒回.mp4" type="video/mp4" />
      </video>
    </div> -->
    <div class="cont">
      <div class="cloud db">
        <div class="cloud-left db v">
          <div
            class="cloud-cont-bg h210 db v animate__animated animate__fadeOutLeft animate__delay20"
          >
            <h6>资源健康状态监控</h6>
            <div class="fx1 db vh">
              <div class="cloud-monitor-pic fx1">
                <img
                  src="../../assets/images/page4/icon-action.png"
                  width="54"
                  class="animate__animated_up"
                />
              </div>
              <div class="cloud-monitor-right">
                <div class="cloud-monitor-txt">
                  <p>正常</p>
                  <b>1728</b>
                </div>
                <div class="cloud-monitor-txt">
                  <p>提示</p>
                  <b>1728</b>
                </div>
                <div class="cloud-monitor-txt">
                  <p>重要</p>
                  <b class="color-ye">0</b>
                </div>
                <div class="cloud-monitor-txt">
                  <p>严重</p>
                  <b class="color-ye">1</b>
                </div>
              </div>
            </div>
          </div>
          <div
            class="cloud-cont-bg fx1 db v animate__animated animate__fadeOutLeft animate__delay20"
          >
            <h6>资源管理状态</h6>
            <div class="cloud-state fx1 db v">
              <div class="cloud-state-top cloud-state-txt">
                <p class="cloud-state-add">今日新增</p>
                <p>数据<i>89992</i>GB</p>
                <p>存储<i class="color-ye">23332</i>GB</p>
              </div>
              <div class="fx1 db v">
                <div class="cloud-state-cont fx1 db vh">
                  <div style="width: 100%">
                    <div class="cloud-state-txt">
                      <p>云数据</p>
                      <p>总存储<i>2218</i>GB/S</p>
                    </div>
                    <p class="cloud-state-bot"><b style="width: 40%"></b></p>
                  </div>
                </div>
                <div class="cloud-state-cont fx1 db vh">
                  <div style="width: 100%">
                    <div class="cloud-state-txt">
                      <p>云存储</p>
                      <p>总存储<i class="color-ye">3218</i>GB/S</p>
                    </div>
                    <p class="cloud-state-bot">
                      <b style="width: 60%" class="color-ye"></b>
                    </p>
                  </div>
                </div>
                <div class="cloud-state-cont fx1 db vh" style="margin-bottom: 15px">
                  <div style="width: 100%">
                    <div class="cloud-state-txt">
                      <p>硬盘</p>
                      <p>总存储<i class="color-blue">3218</i>GB/S</p>
                    </div>
                    <p class="cloud-state-bot">
                      <b style="width: 60%" class="color-blue"></b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="cloud-cont-bg fx1 mt10 db v animate__animated animate__fadeOutLeft animate__delay20 fit"
          >
            <h6>资源处理数量</h6>
            <div class="fx1" id="handleNum"></div>
          </div>
        </div>
        <div class="fx1 db vm">
          <div class="cloudMid">
            <div class="videoTag1 videoTag animate__animated animate__fadeInUp">
              <img src="../../assets/images/page4/video-alarm-cream.png" />
            </div>
            <div
              class="videoTag2 videoTag animate__animated animate__fadeInUp animate__delay1"
            >
              <img src="../../assets/images/page4/video-alarm-cream1.png" />
            </div>
            <div
              class="videoTag3 videoTag animate__animated animate__fadeInUp animate__delay2"
            >
              <img src="../../assets/images/page4/video-alarm-cream2.png" />
            </div>
            <div
              class="videoTag4 videoTag animate__animated animate__fadeInUp animate__delay3"
            >
              <img src="../../assets/images/page4/video-alarm-cream3.png" />
            </div>
            <div
              class="videoTag5 videoTag animate__animated animate__fadeInUp animate__delay4"
            >
              <img src="../../assets/images/page4/video-alarm-cream4.png" />
            </div>
            <div
              class="videoTag6 videoTag animate__animated animate__fadeInUp animate__delay5"
            >
              <img src="../../assets/images/page4/video-alarm-cream5.png" />
            </div>
            <div
              class="videoTag7 videoTag animate__animated animate__fadeInUp animate__delay6"
            >
              <img src="../../assets/images/page4/video-alarm-cream6.png" />
            </div>
            <!-- <div
              class="videoTag8 animate__animated animate__fadeInUp animate__delay7"
            >
              <img src="../../assets/images/cloud/video-hov-cream.png" />
            </div> -->
          </div>
        </div>
        <div class="cloud-right db v">
          <div
            class="cloud-cont-bg fx1 db v animate__animated animate__fadeOutRight animate__delay20"
          >
            <h6>TOP5资源类型告警次数</h6>
            <div class="fx1 db vh">
              <ul class="cloud-warn">
                <li>
                  <span>1</span>
                  <p class="cloud-warn-name">武汉云</p>
                  <p class="fx1 text-l"><b style="width: 90%"></b></p>
                  <strong>1022</strong>
                </li>
                <li>
                  <span>2</span>
                  <p class="cloud-warn-name">天翼云</p>
                  <p class="fx1 text-l"><b style="width: 70%"></b></p>
                  <strong>982</strong>
                </li>
                <li>
                  <span>3</span>
                  <p class="cloud-warn-name">楚天云</p>
                  <p class="fx1 text-l"><b style="width: 60%"></b></p>
                  <strong>688</strong>
                </li>
                <li>
                  <span>4</span>
                  <p class="cloud-warn-name">移动云</p>
                  <p class="fx1 text-l"><b style="width: 65%"></b></p>
                  <strong>721</strong>
                </li>
                <li>
                  <span>5</span>
                  <p class="cloud-warn-name">金山云</p>
                  <p class="fx1 text-l"><b style="width: 40%"></b></p>
                  <strong>321</strong>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="statistics cloud-cont-bg animate__animated animate__fadeOutRight animate__delay20"
          >
            <h6>资源采集异常统计</h6>
            <div class="cloud-style">
              <div class="cloud-til pr">
                资源类别管理
                <p>
                  <span class="on">今日</span> ｜ <span>本周</span> ｜
                  <span>本月</span>
                </p>
              </div>
              <div class="cloud-style-cont db">
                <div class="fx1 pr" id="cloudStyle1" style="z-index: 2"></div>
                <div class="fx1 pr" id="cloudStyle2" style="z-index: 2"></div>
                <div class="fx1 pr" id="cloudStyle3" style="z-index: 2"></div>
              </div>
              <div style="height: 0" class="db">
                <div class="fx1 pr" style="z-index: 1">
                  <div class="cloud-style-txt">
                    <span><b>28</b>%</span>
                    <p>云监控</p>
                  </div>
                </div>
                <div class="fx1 pr" style="z-index: 1">
                  <div class="cloud-style-txt">
                    <span><b>28</b>%</span>
                    <p>云主机</p>
                  </div>
                </div>
                <div class="fx1 pr" style="z-index: 1">
                  <div class="cloud-style-txt">
                    <span><b>28</b>%</span>
                    <p>云网络</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="cloud-add">
              <div class="cloud-til">每日新增告警管理</div>
              <ul>
                <li class="cloud-add-on">
                  <span>处理中</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
                <li class="cloud-add-over">
                  <span>已处置</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
                <li>
                  <span>待处理</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
                <li>
                  <span>待处理</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
                <li>
                  <span>待处理</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
                <li>
                  <span>待处理</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
                <li class="cloud-add-on">
                  <span>处理中</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
                <li class="cloud-add-over">
                  <span>已处置</span>
                  <p>IP0002999200011002</p>
                  <i>2023-02-21</i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
import * as echarts from "echarts";

export default {
  name: "CloudPage",
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getHandleNum();
      this.getCloudStyle();
    },
    getHandleNum() {
      let myChart = echarts.init(document.getElementById("handleNum"));
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor:
            "linear-gradient(180deg, #1A244E 0%, rgba(17,13,73,0.78) 100%)",
          textStyle: {
            color: "#FFFFFF",
          },
          borderColor: "#4E80CF",
        },
        legend: {
          data: ["待处置", "已处置"],
          x: "right",
          y: "8%",
          textStyle: {
            color: "rgba(228, 246, 255, .5)",
          },
          icon: "rect",
          itemWidth: 10,
          itemHeight: 4,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["06-01", "06-02", "06-03", "06-04", "06-05", "06-06", "06-07"],
          axisLine: {
            lineStyle: {
              // X 轴颜色配置
              color: "rgba(228, 246, 255, .5)",
            },
          },
        },
        yAxis: {
          type: "value",
          splitNumber: "3",
          axisLine: {
            lineStyle: {
              // X 轴颜色配置
              color: "rgba(228, 246, 255, .5)",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, .1)",
            },
          },
        },
        series: [
          {
            name: "待处置",
            type: "line",
            stack: "Total",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "已处置",
            type: "line",
            stack: "Total",
            data: [220, 182, 191, 234, 290, 330, 310],
          },
        ],
        color: ["#04C6FD", "#44D7B6"],
      });
    },
    getCloudStyle() {
      let myChart1 = echarts.init(document.getElementById("cloudStyle1"));
      let myChart2 = echarts.init(document.getElementById("cloudStyle2"));
      let myChart3 = echarts.init(document.getElementById("cloudStyle3"));
      const data = {
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["60%", "72%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [{ value: 35 }, { value: 100 }],
            color: ["#4CECFF", "rgba(10,229,255,0)"],
          },
        ],
      };
      myChart1.setOption(data);
      myChart2.setOption(data);
      myChart3.setOption(data);
    },
  },
};
</script>
