<template>
  <div>
    <div
      class="PageSix-cloud page11-1"
      :class="{
        'animate__animated animate__fadeInUp': isShow,
        animate__flipOutY: !isShow,
      }"
    >
      <img src="../../assets/images/page11/page11-01.png" />
    </div>
    <div
      v-if="isShow11"
      class="PageSix-cloud page11-2"
      :class="{
        'animate__animated animate__animated_duration animate__fadeInUp':
          isShow1,
        animate__flipOutY: !isShow1,
      }"
    >
      <img src="../../assets/images/page11/page11-02.png" />
    </div>
    <div
      v-if="isShow21"
      class="PageSix-cloud page11-3"
      :class="{
        'animate__animated animate__animated_duration animate__fadeInUp':
          isShow2,
        animate__flipOutY: !isShow2,
      }"
    >
      <img src="../../assets/images/page11/page11-03.png" />
    </div>
    <div
      v-if="isShow31"
      class="PageSix-cloud page11-4"
      :class="{
        'animate__animated animate__animated_duration animate__fadeInUp':
          isShow3,
        animate__flipOutY: !isShow3,
      }"
    >
      <img src="../../assets/images/page11/page11-04.png" />
    </div>
    <div
      v-if="isShow41"
      class="PageSix-cloud page11-5"
      :class="{
        'animate__animated animate__animated_duration animate__fadeInUp':
          isShow4,
        animate__flipOutY: !isShow4,
      }"
    >
      <img src="../../assets/images/page11/page11-05.png" />
    </div>
    <div
      v-if="isShow51"
      class="PageSix-cloud page11-6"
      :class="{
        'animate__animated animate__animated_duration animate__fadeIn':
          isShow51,
        'animate__animated animate__animated_duration animate__slideInDown PageSix-cloud-animate':
          isShow5,
      }"
    >
      <img src="../../assets/images/page11/logo.png" />
    </div>
    <video :controls="false" autoplay>
      <source
        src="http://rwx3y0ja5.hb-bkt.clouddn.com/page11-%E7%89%87%E5%B0%BE.mp4?e=1688103624&token=fji8x5q1di6bLpzZ1s_Cn5Zt09sPWnoQhXeWK3Ku:z4Gar3H9MXhxS8Gh2sKaegxXckI="
        type="video/mp4"
      />
    </video>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "PageEleven",
  data() {
    return {
      isShow: true,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow11: false,
      isShow21: false,
      isShow31: false,
      isShow41: false,
      isShow5: false,
      isShow51: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow = false;
      this.isShow1 = true;
      this.isShow11 = true;
    }, 1400);
    setTimeout(() => {
      this.isShow1 = false;
      this.isShow2 = true;
      this.isShow21 = true;
      setTimeout(() => {
        this.isShow11 = false;
      }, 1000);
    }, 2800);
    setTimeout(() => {
      this.isShow2 = false;
      this.isShow3 = true;
      this.isShow31 = true;
      setTimeout(() => {
        this.isShow21 = false;
      }, 1000);
    }, 4200);
    setTimeout(() => {
      this.isShow3 = false;
      this.isShow4 = true;
      this.isShow41 = true;
      setTimeout(() => {
        this.isShow31 = false;
      }, 1000);
    }, 5600);
    setTimeout(() => {
      this.isShow4 = false;
      setTimeout(() => {
        this.isShow41 = false;
        setTimeout(() => {
          this.isShow51 = true;
          setTimeout(() => {
            this.isShow5 = true;         
          }, 2000);
        }, 3000);
      }, 1000);
    }, 7000);
  },
  methods: {},
};
</script>