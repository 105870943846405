<template>
  <div class="cloud db page13">
    <div class="cloud-left db v">
      <div class="h250 cloud-cont-bg db v">
        <h6>产品消费统计</h6>
        <div class="fx1">
          <p class="proAll">总收入</p>
          <div class="proText">
            <span>4</span>
            <span>5</span>
            <span>3</span>
            <span>6</span>
            <span>3</span>
            <b>万元</b>
          </div>
          <div class="proIncome db">
            <p>近一月收入<br /><b>5221</b>万元</p>
            <p>近半年收入<br /><b>9900</b>万元</p>
            <p>近一年收入<br /><b>11900</b>万元</p>
          </div>
        </div>
      </div>
      <div class="h250 cloud-cont-bg mt10 db v">
        <h6>产品营销趋势图</h6>
        <div class="fx1" id="market"></div>
      </div>
      <div class="cloud-cont-bg fx1 mt10 db v">
        <h6>产品营销TOP5</h6>
        <div class="fx1 db v">
          <ul class="proMarketCont">
            <li>
              <div class="proMarket">
                <p>云主机</p>
                <p>45</p>
              </div>
              <span><b style="width: 80%"></b></span>
            </li>
            <li>
              <div class="proMarket">
                <p>负载均衡</p>
                <p>30</p>
              </div>
              <span><b style="width: 60%"></b></span>
            </li>
            <li>
              <div class="proMarket">
                <p>对象存储</p>
                <p>25</p>
              </div>
              <span><b style="width: 50%"></b></span>
            </li>
            <li>
              <div class="proMarket">
                <p>云数据库Redis</p>
                <p>18</p>
              </div>
              <span><b style="width: 40%"></b></span>
            </li>
            <li>
              <div class="proMarket">
                <p>Web应用防火墙</p>
                <p>8</p>
              </div>
              <span><b style="width: 20%"></b></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fx1 pr">
      <div class="center-part">
        <img src="../../assets/images/page13/map1.png"  class="centerImg" style="width: 605px"/>
        <div class="num1">订单成交量 <span>19,900</span> 笔，总额度达到 <span>5,449,000.00</span> 万元</div>
        <div class="page13Line1">
          <div class="linePart1">2,900</div>
          <div class="linePart2">委办数量</div>
        </div>
        <div class="page13Line2">
          <div class="linePart1">219</div>
          <div class="linePart2">云服务商数（个）</div>
        </div>
        <div class="page13Line3">
          <div class="linePart1">2,781</div>
          <div class="linePart2">订单数量（个）</div>
        </div>
        <div class="page13Line4">
          <div class="linePart1">72,781</div>
          <div class="linePart2">金额总数（万元）</div>
        </div>
        <dv-flyline-chart-enhanced :config="config" :dev="true" style="width:100%;height:100%;" />
        <div class="page13IconPart">
          <div class="page13Icon1 page13Icon">
            <div class="onlyIcon">光谷云</div>
            <div><img src="../../assets/images/page13/GPS-nor.png" /></div>
          </div>
          <div class="page13Icon2 page13Icon">
            <!-- <div class="page13IconText">云物理机</div> -->
            <div class="page13IconImg"><img src="../../assets/images/page13/icon-poi.png" /></div>
          </div>
          <div class="page13Icon3 page13Icon">
            <!-- <div class="page13IconText">云物理机</div> -->
            <div class="page13IconImg"><img src="../../assets/images/page13/icon-poi.png" /></div>
          </div>
          <div class="page13Icon4 page13Icon">
            <!-- <div class="page13IconText">云物理机</div> -->
            <div class="page13IconImg"><img src="../../assets/images/page13/icon-poi.png" /></div>
          </div>
          <div class="page13Icon5 page13Icon">
            <!-- <div class="page13IconText">云物理机</div> -->
            <div class="page13IconImg"><img src="../../assets/images/page13/icon-poi.png" /></div>
          </div>
          <div class="page13Icon6 page13Icon">
            <!-- <div class="page13IconText">云物理机</div> -->
            <div class="page13IconImg"><img src="../../assets/images/page13/icon-poi.png" /></div>
          </div>
          <div class="page13Icon7 page13Icon">
            <!-- <div class="page13IconText">云物理机</div> -->
            <div class="page13IconImg"><img src="../../assets/images/page13/icon-poi.png" /></div>
          </div>
          <div class="page13Icon8 page13Icon">
            <!-- <div class="page13IconText">云物理机</div> -->
            <div class="page13IconImg"><img src="../../assets/images/page13/icon-poi.png" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cloud-right db v">
      <div class="h250 cloud-cont-bg db v">
        <h6>用户数量统计</h6>
        <div class="fx1">
          <div class="cloud-state-top cloud-state-txt userNum">
            <p class="userNumTxt">用户总数量<strong>89992</strong>个</p>
          </div>
          <div class="proIncome db">
            <p><i>近一月用户增长率</i><b>32</b>%</p>
            <p><i>近半年用户增长率</i><b>15</b>%</p>
            <p><i>近一年用户增长率</i><b>12</b>%</p>
          </div>
        </div>
      </div>
      <div class="h250 cloud-cont-bg mt10 db v">
        <h6>用户增长趋势图</h6>
        <div class="fx1" id="userAdd"></div>
      </div>
      <div class="cloud-cont-bg fx1 mt10 db v fit">
        <h6>活跃用户分析</h6>
        <div class="activeUser fx1 db v">
          <div class="cloud-til pr">
            资源类别管理
            <p>
              <span class="on">今日</span> ｜ <span>本周</span> ｜ <span>本月</span>
            </p>
          </div>
          <div class="fx1" id="activeUser"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
import * as echarts from 'echarts';

export default {
  name: "CloudPage",
  data() {
    return {
      config: {
        line: {
          color: '#fff',
          width: 3,
        },
        halo: {
          show: false
        },
        points: [{
          name: 'icon1',
          coordinate: [0.49, 0.37]
        },{
          name: 'icon2',
          coordinate: [0.54, 0.25]
        },{
          name: 'icon3',
          coordinate: [0.44, 0.34]
        },{
          name: 'icon4',
          coordinate: [0.58, 0.38]
        },{
          name: 'icon5',
          coordinate: [0.39, 0.38]
        },{
          name: 'icon6',
          coordinate: [0.51, 0.52]
        },{
          name: 'icon7',
          coordinate: [0.66, 0.37]
        },{
          name: 'icon8',
          coordinate: [0.66, 0.28]
        },],
        lines: [{
      source: 'icon2',
      target: 'icon1'
    },{
      source: 'icon3',
      target: 'icon1'
    },{
      source: 'icon4',
      target: 'icon1'
    },{
      source: 'icon5',
      target: 'icon1'
    },{
      source: 'icon6',
      target: 'icon1'
    },{
      source: 'icon7',
      target: 'icon1'
    },{
      source: 'icon8',
      target: 'icon1'
    },]
      }
    };
  },
  mounted() {
    this.init();
    setTimeout(() => {
      document.getElementsByClassName("dv-flyline-chart-enhanced")[0].style.zIndex = '9999';
    }, 100);
  },
  methods: {
    init() {
      this.getMarket()
      this.getUserAdd()
      this.getActiveUser()
    },
    getMarket() {
      let myChart = echarts.init(document.getElementById('market'));
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(17,13,73,0.78)',
          borderColor: '#4E80CF',
          textStyle: { // 提示框浮层的文本样式。
            color: '#D1DDFF',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 14,
          }
        },
        xAxis: {
          type: 'category',
          data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07'],
          axisLine: {
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'
            }
          },
        },
        yAxis: {
          type: 'value',
          splitNumber: '4',
          axisLine: {
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, .1)',
            },
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          top: '18%',
          containLabel: true
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
            areaStyle: {}
          }
        ],
        color: ['rgba(70, 132, 233, 0.39)']
      });
    },
    getUserAdd() {
      let myChart = echarts.init(document.getElementById('userAdd'));
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(17,13,73,0.78)',
          borderColor: '#4E80CF',
          textStyle: { // 提示框浮层的文本样式。
            color: '#D1DDFF',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 14,
          }
        },
        xAxis: {
          type: 'category',
          data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07'],
          axisLine: {
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'
            }
          },
        },
        yAxis: {
          type: 'value',
          splitNumber: '4',
          axisLine: {
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, .1)',
            },
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          top: '18%',
          containLabel: true
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
            areaStyle: {

            }
          }
        ],
        color: ['rgba(70, 132, 233, 0.39)']
      });
    },
    getActiveUser() {
      let myChart = echarts.init(document.getElementById('activeUser'));
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(17,13,73,0.78)',
          borderColor: '#4E80CF',
          textStyle: { // 提示框浮层的文本样式。
            color: '#D1DDFF',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 14,
          }
        },
        xAxis: {
          type: 'category',
          data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07'],
          axisLine: {
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'
            }
          },
        },
        yAxis: {
          type: 'value',
          splitNumber: '4',
          axisLine: {
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, .1)',
            },
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          top: '18%',
          containLabel: true
        },
        series: [
          {
            name: '类别一',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            type: 'line',
            smooth: true,
            areaStyle: {}
          },
          {
            name: '类别二',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
            type: 'line',
            smooth: true,
            areaStyle: {}
          },
        ],
        color: ['rgba(70, 132, 233, 0.39)', 'rgba(0,214,139,.39)']
      });
    }
  },
};
</script>
