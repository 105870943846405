var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contBox"},[_c('div',{staticClass:"contMask"}),_c('div',{staticClass:"contVideo"},[_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"controls":false,"autoplay":"","loop":""}},[_c('source',{attrs:{"src":require("./顶部效果.mp4"),"type":"video/mp4"}})])]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"PageFive"},[_c('div',{staticClass:"cloud1",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__delay0 animate__flipOutY': !_vm.isShow,
        },staticStyle:{"z-index":"2"}},[_vm._m(0),_c('div',{staticClass:"part2",staticStyle:{"z-index":"2"}})]),_c('div',{staticClass:"cloud2 animate__delay1",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__delay0 animate__flipOutY': !_vm.isShow,
        },staticStyle:{"z-index":"2"}},[_vm._m(1),_c('div',{staticClass:"part2",staticStyle:{"z-index":"2"}})]),_c('div',{staticClass:"cloud3 animate__delay2",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__delay0 animate__flipOutY': !_vm.isShow,
        },staticStyle:{"z-index":"2"}},[_vm._m(2),_c('div',{staticClass:"part2",staticStyle:{"z-index":"2"}})]),_c('div',{staticClass:"cloud4 animate__delay3",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__delay0 animate__flipOutY': !_vm.isShow,
        },staticStyle:{"z-index":"2"}},[_vm._m(3),_c('div',{staticClass:"part2 animate__fadeInUp animate__animated",staticStyle:{"z-index":"2"}})]),_c('div',{staticClass:"cloud5 animate__delay4",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__delay0 animate__flipOutY': !_vm.isShow,
        },staticStyle:{"z-index":"2"}},[_vm._m(4),_c('div',{staticClass:"part2",staticStyle:{"z-index":"2"}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"text",staticStyle:{"z-index":"2"}},[_vm._v("市人大常委会办公室")]),_c('div',{staticClass:"imgPart",staticStyle:{"z-index":"2"}},[_c('img',{attrs:{"src":require("@/assets/images/page4/img1.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"text",staticStyle:{"z-index":"2"}},[_vm._v("网站新媒体部")]),_c('div',{staticClass:"imgPart",staticStyle:{"z-index":"2"}},[_c('img',{attrs:{"src":require("@/assets/images/page4/img1.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"text",staticStyle:{"z-index":"2"}},[_vm._v("市科技局")]),_c('div',{staticClass:"imgPart",staticStyle:{"z-index":"2"}},[_c('img',{attrs:{"src":require("@/assets/images/page4/img1.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"text",staticStyle:{"z-index":"2"}},[_vm._v("市信访局")]),_c('div',{staticClass:"imgPart",staticStyle:{"z-index":"2"}},[_c('img',{attrs:{"src":require("@/assets/images/page4/img1.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"text",staticStyle:{"z-index":"2"}},[_vm._v("市社科联")]),_c('div',{staticClass:"imgPart",staticStyle:{"z-index":"2"}},[_c('img',{attrs:{"src":require("@/assets/images/page4/img1.jpg")}})])])
}]

export { render, staticRenderFns }