<template>
  <div class="contBox">
    <div class="contMask"></div>
    <div class="contVideo">
      <video
          :controls="false"
          autoplay
          loop
          style="width: 100%;height: 100%;"
        >
        <source src="./环绕.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="cont">
      <div class="PageSix">
        <div v-if="isShow" class="content">
          <div
            class="PageSix-cloud"
            :class="{
              'animate__animated animate__fadeInUp': isShow1,
              animate__flipOutY: !isShow1,
            }"
          >
            <div class="part1 db v">
              <div class="text">云主机</div>
              <div class="imgPart fx1 db vm">
                可随时自助获取、弹性伸缩，帮助您打造可靠、安全、灵活、高效的应用环境，确保服务持久稳定运行，提升运维效率。
              </div>
            </div>
          </div>
          <div
            class="PageSix-cloud animate__delay1"
            :class="{
              'animate__animated animate__fadeInUp': isShow1,
              animate__flipOutY: !isShow1,
            }"
          >
            <div class="part1 db v">
              <div class="text">云硬盘</div>
              <div class="imgPart fx1 db vm">
                为云主机提供高可靠、高性能、规格丰富并且可弹性扩展的块存储服务，可满足不同场景的业务需求，适用于分布式文件系统、开发测试、数据仓库以及高性能计算等场景。
              </div>
            </div>
            <div class="part2"></div>
          </div>
          <div
            class="PageSix-cloud animate__delay2"
            :class="{
              'animate__animated animate__fadeInUp': isShow1,
              animate__flipOutY: !isShow1,
            }"
          >
            <div class="part1 db v">
              <div class="text">WEB应用防火墙</div>
              <div class="imgPart fx1 db vm">
                通过对http、https请求进行检测，识别并阻断SQL注入、跨站脚本攻击、网页木马上传、命令/代码注入、文件包含、敏感文件访问、第三方应用漏洞攻击、CC攻击、恶意爬虫扫描、跨站请求伪造等攻击，保护Web服务安全稳定
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isShow" class="content">
          <div
            class="PageSix-cloud"
            :class="{
              'animate__animated animate__fadeInUp': isShow2,
              animate__flipOutY: !isShow2,
            }"
          >
            <div class="part1 db v">
              <div class="text">虚拟私有云</div>
              <div class="imgPart fx1 db vm">
                自定义网络划分、路由规则，配置实施立即生效，100%逻辑隔离的网络空间，支持公网VPN接入和专线接入，精确到端口的网络控制，满足金融政企的安全要求。
              </div>
            </div>
          </div>
          <div
            class="PageSix-cloud animate__delay1"
            :class="{
              'animate__animated animate__fadeInUp': isShow2,
              animate__flipOutY: !isShow2,
            }"
          >
            <div class="part1 db v">
              <div class="text">云主机备份</div>
              <div class="imgPart fx1 db vm">
                提供简单易用的备份服务，当发生病毒入侵、人为误删除等事件时，可将数据恢复到任意备份点，为您的业务运行保驾护航。
              </div>
            </div>
          </div>
          <div
            class="PageSix-cloud animate__delay2"
            :class="{
              'animate__animated animate__fadeInUp': isShow2,
              animate__flipOutY: !isShow2,
            }"
          >
            <div class="part1 db v">
              <div class="text">对象存储（OBS）</div>
              <div class="imgPart fx1 db vm">
                提供海量、安全、高可靠、低成本的数据存储能力，可供您存储任意类型和大小的数据。适合备份/归档、视频点播、视频监控等多种数据存储场景。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "PageThree",
  components: {},
  data() {
    return {
      isShow: true,
      isShow1: true,
      isShow2: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow1 = false;
    }, 3000);
    setTimeout(() => {
      this.isShow = false;
    }, 5000);
    setTimeout(() => {
      this.isShow2 = false;
    }, 8000);
  },
  methods: {},
};
</script>