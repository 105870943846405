<template>
  <div class="contBox">
    <div class="contMask"></div>
    <div class="contVideo">
      <video
          :controls="false"
          autoplay
          loop
          style="width: 100%;height: 100%;"
        >
        <source src="./近景建筑效果.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="cont">
      <div class="PageThreeBox db vm">
        <div class="PageThree animate__animated animate__fadeIn">
          <div class="PageThreeCloud cloud1">
            <div
              class="ThreeCloudCont"
              :class="{ animate__ThreeCloudCont_: !isShow3 }"
            >
              <img
                src="../../assets/images/page3/plant01-bg.png"
                width="188"
                style="margin-right: 20px"
              />
              <div class="PageThreeBg"></div>
              <div class="ThreeCloudImg img2">
                <img src="@/assets/images/page3/img01-1.png" width="238" />
              </div>
              <div class="ThreeCloudImg img3">
                <img src="@/assets/images/page3/img01-3.png" width="238" />
              </div>
              <div class="ThreeCloudImg img4">
                <img src="@/assets/images/page3/img01-2.png" width="238" />
              </div>
            </div>
          </div>
          <div class="PageThreeCloud cloud2">
            <div
              class="ThreeCloudCont"
              :class="{ animate__ThreeCloudCont_: !isShow2 }"
            >
              <img
                src="../../assets/images/page3/plant02-bg.png"
                style="margin-right: 20px"
              />
              <div class="PageThreeBg"></div>
              <div class="ThreeCloudImg img2">
                <img src="@/assets/images/page3/img02-1.png" width="238" />
              </div>
              <div class="ThreeCloudImg img3">
                <img src="@/assets/images/page3/img02-3.png" width="238" />
              </div>
              <div class="ThreeCloudImg img4">
                <img src="@/assets/images/page3/img02-2.png" width="238" />
              </div>
            </div>
          </div>
          <div class="PageThreeCloud cloud3">
            <div
              class="ThreeCloudCont"
              :class="{ animate__ThreeCloudCont_: !isShow1 }"
            >
              <img
                src="../../assets/images/page3/plant03-bg.png"
                style="margin-right: 20px"
              />
              <div class="PageThreeBg"></div>
              <div class="ThreeCloudImg img2">
                <img src="@/assets/images/page3/img03-1.png" width="238" />
              </div>
              <div class="ThreeCloudImg img3">
                <img src="@/assets/images/page3/img03-3.png" width="238" />
              </div>
              <div class="ThreeCloudImg img4">
                <img src="@/assets/images/page3/img03-2.png" width="238" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "PageThree",
  data() {
    return {
      isShow1: true,
      isShow2: true,
      isShow3: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow1 = false;
    }, 2000);
    setTimeout(() => {
      this.isShow2 = false;
    }, 4000);
    setTimeout(() => {
      this.isShow3 = false;
    }, 6000);
  },
  methods: {},
};
</script>