<template>
  <div class="cloud db resource">
    <div class="cloud-left db v">
      <div class="h210 cloud-cont-bg db v">
        <h6>当前数据中心资源趋势图</h6>
        <div class="fx1" id="handleNum"></div>
      </div>
      <div class="h270 cloud-cont-bg mt10 db v">
        <h6>资源管理状态</h6>
        <div class="cloud-state fx1 db v" style="margin-bottom: 15px">
          <div class="cloud-state-top cloud-state-txt">
            <p>CPU<i>89992</i>GB</p>
            <p>内存<i class="color-ye">89992</i>GB</p>
            <p>存储<i class="color-blue">23332</i>GB</p>
          </div>
          <div class="cloud-state-cont fx1">
            <div class="cloud-state-txt">
              <p>CPU使用率 89%</p>
              <p>总存储<i>2218</i>MHz</p>
            </div>
            <p class="cloud-state-bot"><b style="width: 40%"></b></p>
          </div>
          <div class="cloud-state-cont fx1">
            <div class="cloud-state-txt">
              <p>内存使用率 69%</p>
              <p>总存储<i class="color-ye">3218</i>GB</p>
            </div>
            <p class="cloud-state-bot"><b style="width: 60%" class="color-ye"></b></p>
          </div>
          <div class="cloud-state-cont">
            <div class="cloud-state-txt">
              <p>硬盘使用率 49%</p>
              <p>总存储<i class="color-blue">3218</i>GB</p>
            </div>
            <p class="cloud-state-bot"><b style="width: 60%" class="color-blue"></b></p>
          </div>
        </div>
      </div>
      <div class="cloud-cont-bg fx1 db v mt10">
        <h6>虚拟机CPU利用率Bottom</h6>
        <div class="fx1 db vh">
          <ul class="cloud-warn">
            <li>
              <p class="cloud-warn-name">高明区卫生院…</p>
              <p class="fx1 text-l"><b style="width: 90%"></b></p>
              <strong>1022</strong>
            </li>
            <li>
              <p class="cloud-warn-name">信创智控平台…</p>
              <p class="fx1 text-l"><b style="width: 70%"></b></p>
              <strong>982</strong>
            </li>
            <li>
              <p class="cloud-warn-name">工资信息管理…</p>
              <p class="fx1 text-l"><b style="width: 60%"></b></p>
              <strong>688</strong>
            </li>
            <li>
              <p class="cloud-warn-name">算法服务器</p>
              <p class="fx1 text-l"><b style="width: 65%"></b></p>
              <strong>721</strong>
            </li>
            <li>
              <p class="cloud-warn-name">适配中心测试</p>
              <p class="fx1 text-l"><b style="width: 40%"></b></p>
              <strong>321</strong>
            </li>
            <li>
              <p class="cloud-warn-name">互联网接入机</p>
              <p class="fx1 text-l"><b style="width: 60%"></b></p>
              <strong>688</strong>
            </li>
            <li>
              <p class="cloud-warn-name">虚拟机网络</p>
              <p class="fx1 text-l"><b style="width: 65%"></b></p>
              <strong>721</strong>
            </li>
            <li>
              <p class="cloud-warn-name">无人机备份服…</p>
              <p class="fx1 text-l"><b style="width: 40%"></b></p>
              <strong>321</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fx1 pr">
      <div class="imgTag2" v-show="!detailFlag">
        <img class="imgTag1" src="../../assets/images/page4/logo-浮动action.png" />
        <img class="imgTag1_bottom" src="../../assets/images/page4/img-bg.png" />
      </div>
      <div class="imgTag3" v-show="!detailFlag"><img src="../../assets/images/resource/logo-round.png" /></div>
      <div class="imgTag5" v-show="!detailFlag"><img src="../../assets/images/resource/运营管理平台.png" /></div>
      <dv-flyline-chart v-show="!detailFlag" class="line" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line1" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line2" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line3" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line4" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line5" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line6" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line7" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line8" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line9" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line10" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line11" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line12" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <dv-flyline-chart v-show="!detailFlag" class="line13" :config="config" :dev="true" style="width:400px;height:400px;"/>
      <div class="imgTag4 imgTagTxt ball1" @mouseenter="stop = true"  v-show="!detailFlag">天翼云</div>
      <div class="imgTag4 imgTagTxt ball2" v-show="!detailFlag">武汉云</div>
      <div class="imgTag4 imgTagTxt ball3" v-show="!detailFlag">双中心</div>
      <div class="imgTag4 imgTagTxt ball4" v-show="!detailFlag">自建云</div>
      <div class="imgTag4 imgTagTxt ball5" v-show="!detailFlag">楚天云</div>
      <div class="imgTag4 imgTagTxt ball6" v-show="!detailFlag">金山云</div>
      <div class="imgTag4 imgTagTxt ball7" v-show="!detailFlag">迈异云</div>
      <div class="detail" v-show="!detailFlag">
        <img src="../../assets/images/resource/round.png"/>
      </div>
      <div class="detail1" v-show="detailFlag">
        <img src="../../assets/images/resource/round.png"/>
      </div>
      <div class="about-cont animate__animated animate__fadeInUp" v-show="detailFlag">
      {{showText}}
    </div>
    </div>
    <div class="cloud-right db v">
      <div class="h270 cloud-cont-bg fx1 db v">
        <h6>TOP5资源申请工单数</h6>
        <div class="fx1 db vh" id="apply"></div>
      </div>
      <div class="statistics cloud-cont-bg">
        <h6>资源工单事件统计</h6>
        <div class="cloud-style">
          <div class="cloud-til pr">
            资源类别管理
            <p>
              <span class="on">今日</span> ｜ <span>本周</span> ｜ <span>本月</span>  
            </p>
          </div>
          <div class="cloud-style-cont db">
            <div class="fx1 pr" id="cloudStyle1" style="z-index: 2"></div>
            <div class="fx1 pr" id="cloudStyle2" style="z-index: 2"></div>
            <div class="fx1 pr" id="cloudStyle3" style="z-index: 2"></div>
          </div>
          <div style="height: 0" class="db">
            <div class="fx1 pr" style="z-index: 1">
              <div class="cloud-style-txt">
                <span><b>28</b>%</span>
                <p>云监控</p>
              </div>
            </div>
            <div class="fx1 pr" style="z-index: 1">
              <div class="cloud-style-txt">
                <span><b>28</b>%</span>
                <p>云主机</p>
              </div>
            </div>
            <div class="fx1 pr" style="z-index: 1">
              <div class="cloud-style-txt">
                <span><b>28</b>%</span>
                <p>云网络</p>
              </div>
            </div>
          </div>
        </div>
        <div class="cloud-add">
          <div class="cloud-til">每日新增告警管理</div>
          <ul>
            <li class="cloud-add-on">
              <span>处理中</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
            <li class="cloud-add-over">
              <span>已处置</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
            <li>
              <span>待处理</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
            <li>
              <span>待处理</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
            <li>
              <span>待处理</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
            <li>
              <span>待处理</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
            <li class="cloud-add-on">
              <span>处理中</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
            <li class="cloud-add-over">
              <span>已处置</span>
              <p>IP0002999200011002</p>
              <i>2023-02-21</i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
import * as echarts from 'echarts';

export default {
  name: "CloudPage",
  data() {
    return {
      config: {
        halo: {
          show: false
        },
        curvature: 10,
        flylineColor: '#fff',
        centerPoint: [1, 1],
        lineWidth: 2,
        points: [
          [0, 1],
          [0.03, 0.97],
          [0.06, 0.94],
          [0.09, 0.91],
          [0.12, 0.88],
          [0.15, 0.85],
        ],
      },
      detailFlag: false,
      title: '',
      showText: ''
    };
  },
  mounted(){
    this.init();
  },
  methods: {
    init1() {
      const content = '武汉光谷数字产业集团有限公司创办于2022年3月，经营领域覆盖云计算装备技术、计算机系统服务、计算机软硬件及外围设备制造、人工智能行业应用系统集成、信息系统集成、智能控制系统集成、网络技术、软件开发、电子产品销售、信息咨询、数据处理、信息系统运行维护、人工智能公共服务平台技术咨询、互联网安全、互联网数据、租赁服务'
      const _this = this;
 
      this.showText = "";
      clearTimeout(this.timer);
 
      var speed = 200; //设置定时的速度 越来越快
      var count = 1;
 
      function changeContent() {
        _this.showText = content.substring(0, count); //截取字符串
        count++;
 
        if (count != content.length + 1) {
          // speed -= 1;
          // if (speed < 5) speed = 5;
 
          _this.timer = setTimeout(changeContent, speed);
        }
      }
      changeContent();
    },
    showDetail(title){
      this.detailFlag  = true;
      this.title = title;
      this.init1();
    },
    init() {
      this.getHandleNum()
      this.getCloudStyle()
      this.getApply()
    },
    getHandleNum() {
      let myChart = echarts.init(document.getElementById('handleNum'));
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'linear-gradient(180deg, #1A244E 0%, rgba(17,13,73,0.78) 100%)',
          textStyle:{
            color: '#FFFFFF'
          },
          borderColor: '#4E80CF'
        },
        legend: {
          data: ['CPU', '内存'],
          x: 'right',
          y: '8%',
          textStyle:{
          color: 'rgba(228, 246, 255, .5)'
          },
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 4,
        },
        grid: {
          left: '3%',
          right: '5.5%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07'],
          axisLine: { 
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'  
            } 
          },
        },
        yAxis: {
          type: 'value',
          splitNumber: '3',
          axisLine: { 
            lineStyle: { // X 轴颜色配置
              color: 'rgba(228, 246, 255, .5)'  
            } 
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, .1)',
            },
          },
        },
        series: [
          {
            name: 'CPU',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
            smooth: true
          },
          {
            name: '内存',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          },
        ],
        color: ['#04C6FD', '#44D7B6']
      });
    },
    getCloudStyle() {
      let myChart1 = echarts.init(document.getElementById('cloudStyle1'));
      let myChart2 = echarts.init(document.getElementById('cloudStyle2'));
      let myChart3 = echarts.init(document.getElementById('cloudStyle3'));
      const data = {
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['55%', '68%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 120 },
              { value: 100 },
            ],
            color: ['#4CECFF', 'rgba(10,229,255,0)']
          }
        ]
      }
      myChart1.setOption(data);
      myChart2.setOption(data);
      myChart3.setOption(data);
    },
    getApply() {
      let myChart = echarts.init(document.getElementById('apply'));
      myChart.setOption({
        tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
        backgroundColor: 'linear-gradient(180deg, #1A244E 0%, rgba(17,13,73,0.78) 100%)',
          textStyle:{
            color: '#FFFFFF'
          },
          borderColor: '#4E80CF'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '10%',
        containLabel: true
      },
      yAxis: {
        type: 'value',
        splitNumber: '3',
        axisLine: { 
          lineStyle: { // X 轴颜色配置
            color: 'rgba(228, 246, 255, .5)'  
          } 
        },
        splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, .1)',
            },
          },
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisLine: { 
          lineStyle: { // X 轴颜色配置
            color: 'rgba(228, 246, 255, .5)'  
          } 
        },
      },
      series: [
        {
          name: 'Direct',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          barWidth: '45%',
          data: [320, 302, 301, 334, 390, 330, 320]
        },
        {
          name: 'Mail Ad',
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          data: [120, 132, 101, 134, 90, 230, 210]
        },
      ],
      color: ['#04C6FD', '#44D7B6'],
      })
    }
  },
};
</script>
