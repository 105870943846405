<template>
  <div class="contBox">
    <div class="contMask"></div>
    <div class="contVideo">
      <video
          :controls="false"
          autoplay
          loop
          style="width: 100%;height: 100%;"
        >
        <source src="./推近1效果.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="cont">
      <div class="PageTwo">
        <div
          class="center"
          :class="{
            'animate__animated animate__fadeInUp': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <div
          class="cloud1"
          :class="{
            'animate__animated animate__fadeInUp animate__delay1': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <div
          class="cloud2"
          :class="{
            'animate__animated animate__fadeInUp animate__delay2': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <div
          class="cloud3"
          :class="{
            'animate__animated animate__fadeInUp animate__delay3': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <div
          class="cloud4"
          :class="{
            'animate__animated animate__fadeInUp animate__delay4': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <div
          class="cloud5"
          :class="{
            'animate__animated animate__fadeInUp animate__delay5': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <div
          class="cloud6"
          :class="{
            'animate__animated animate__fadeInUp animate__delay6': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <div
          class="cloud7"
          :class="{
            'animate__animated animate__fadeInUp animate__delay7': isShow,
            animate__flipOutY: !isShow,
          }"
          style="z-index: 2"
        ></div>
        <dv-flyline-chart
          :config="config"
          :dev="true"
          style="width: 100%; height: 100%; z-index: 2"
          v-show="showline"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "PageTwo",
  components: {},
  data() {
    return {
      showline: false,
      config: {
        flylineColor: "#fff",
        centerPoint: [0.5, 0.5],
        points: [
          [0.48, 0.2],
          [0.49, 0.21],
          [0.5, 0.22],
          [0.51, 0.23],
          [0.52, 0.24],
          [0.53, 0.25],
          [0.2, 0.5],
          [0.21, 0.51],
          [0.22, 0.52],
          [0.23, 0.53],
          [0.24, 0.54],
          [0.25, 0.55],
          [0.3, 0.3],
          [0.31, 0.32],
          [0.32, 0.33],
          [0.33, 0.34],
          [0.34, 0.35],
          [0.35, 0.36],
          [0.36, 0.7],
          [0.37, 0.71],
          [0.38, 0.72],
          [0.39, 0.73],
          [0.4, 0.74],
          [0.41, 0.75],
          [0.64, 0.7],
          [0.65, 0.71],
          [0.66, 0.72],
          [0.67, 0.73],
          [0.68, 0.74],
          [0.69, 0.75],
          [0.8, 0.5],
          [0.81, 0.51],
          [0.82, 0.52],
          [0.83, 0.53],
          [0.84, 0.54],
          [0.85, 0.55],
          [0.7, 0.3],
          [0.71, 0.31],
          [0.72, 0.32],
          [0.73, 0.33],
          [0.74, 0.34],
          [0.75, 0.35],
        ],
      },
      isShow: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showline = true;
    }, 3500);
    setTimeout(() => {
      this.isShow = false;
      this.showline = false;
    }, 7000);
  },
  methods: {},
};
</script>