<template>
  <div class="pageNineBox">
    <div 
      class="pageNineTil animate__animated"
      :class="{
        'animate__fadeInUp': isShow,
        'animate__fadeOut animate__delay0': !isShow,
      }"
      ></div>
    <div class="pageNine">
      <div
        class="part1 animate__animated"
        :class="{
          'animate__fadeInUp': isShow,
          'animate__fadeOut animate__delay0': !isShow,
        }"
      >
        <div class="title1">运营官网</div>
        <div class="content">
          <div class="list">产品分类</div>
          <div class="list">解决方案</div>
          <div class="list">合作伙伴</div>
          <div class="list">在线咨询</div>
        </div>
      </div>
      <div
        class="part1 animate__animated"
        :class="{
          'animate__fadeInUp animate__delay1': isShow,
          'animate__fadeOut animate__delay0': !isShow,
        }"
      >
        <div class="title1">自服务门户</div>
        <div class="content">
          <div class="list">自助申请</div>
          <div class="list">灵活定义节点</div>
          <div class="list">资产明细</div>
          <div class="list">费用分析</div>
        </div>
      </div>
      <div
        class="part1 animate__animated"
        :class="{
          'animate__fadeInUp animate__delay2': isShow,
          'animate__fadeOut animate__delay0': !isShow,
        }"
      >
        <div class="title1">资源纳管</div>
        <div class="content">
          <div class="list">资产清单</div>
          <div class="list">快速交付</div>
          <div class="list">资源池用量</div>
        </div>
      </div>
      <div
        class="part1 animate__animated"
        :class="{
          'animate__fadeInUp animate__delay3': isShow,
          'animate__fadeOut animate__delay0': !isShow,
        }"
      >
        <div class="title1">工单系统</div>
        <div class="content">
          <div class="list">服务定义</div>
          <div class="list">问题咨询</div>
        </div>
      </div>
      <div
        class="part1 animate__animated"
        :class="{
          'animate__fadeInUp animate__delay4': isShow,
          'animate__fadeOut animate__delay0': !isShow,
        }"
      >
        <div class="title1">基础监控</div>
        <div class="content">
          <div class="list">风险可视化</div>
          <div class="list">硬件监控</div>
          <div class="list">软件监控</div>
          <div class="list">告警阈值定义</div>
        </div>
      </div>
      <div
        class="part1 animate__animated"
        :class="{
          'animate__fadeInUp animate__delay5': isShow,
          'animate__fadeOut animate__delay0': !isShow,
        }"
      >
        <div class="title1">光谷云大屏</div>
        <div class="content">
          <div class="list">云资源大屏</div>
          <div class="list">云数据大屏</div>
          <div class="list">云运营大屏</div>
          <div class="list">云业务大屏</div>
        </div>
      </div>
      <div
        class="part1 animate__animated"
        :class="{
          'animate__fadeInUp animate__delay6': isShow,
          'animate__fadeOut  animate__delay0': !isShow,
        }"
      >
        <div class="title1">领导驾驶舱</div>
        <div class="content">
          <div class="list">监管驾驶舱</div>
          <div class="list">财务驾驶舱</div>
          <div class="list">光谷云驾驶舱</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./index.scss";

export default {
  name: "PageNine",
  data() {
    return {
      isShow: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow = false;
    }, 5000);
  },
  methods: {},
};
</script>