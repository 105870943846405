var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contBox"},[_c('div',{staticClass:"contMask"}),_c('div',{staticClass:"contVideo"},[_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"controls":false,"autoplay":"","loop":""}},[_c('source',{attrs:{"src":require("./环绕.mp4"),"type":"video/mp4"}})])]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"PageSix"},[(_vm.isShow)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"PageSix-cloud",class:{
            'animate__animated animate__fadeInUp': _vm.isShow1,
            animate__flipOutY: !_vm.isShow1,
          }},[_vm._m(0)]),_c('div',{staticClass:"PageSix-cloud animate__delay1",class:{
            'animate__animated animate__fadeInUp': _vm.isShow1,
            animate__flipOutY: !_vm.isShow1,
          }},[_vm._m(1),_c('div',{staticClass:"part2"})]),_c('div',{staticClass:"PageSix-cloud animate__delay2",class:{
            'animate__animated animate__fadeInUp': _vm.isShow1,
            animate__flipOutY: !_vm.isShow1,
          }},[_vm._m(2)])]):_vm._e(),(!_vm.isShow)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"PageSix-cloud",class:{
            'animate__animated animate__fadeInUp': _vm.isShow2,
            animate__flipOutY: !_vm.isShow2,
          }},[_vm._m(3)]),_c('div',{staticClass:"PageSix-cloud animate__delay1",class:{
            'animate__animated animate__fadeInUp': _vm.isShow2,
            animate__flipOutY: !_vm.isShow2,
          }},[_vm._m(4)]),_c('div',{staticClass:"PageSix-cloud animate__delay2",class:{
            'animate__animated animate__fadeInUp': _vm.isShow2,
            animate__flipOutY: !_vm.isShow2,
          }},[_vm._m(5)])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1 db v"},[_c('div',{staticClass:"text"},[_vm._v("云主机")]),_c('div',{staticClass:"imgPart fx1 db vm"},[_vm._v(" 可随时自助获取、弹性伸缩，帮助您打造可靠、安全、灵活、高效的应用环境，确保服务持久稳定运行，提升运维效率。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1 db v"},[_c('div',{staticClass:"text"},[_vm._v("云硬盘")]),_c('div',{staticClass:"imgPart fx1 db vm"},[_vm._v(" 为云主机提供高可靠、高性能、规格丰富并且可弹性扩展的块存储服务，可满足不同场景的业务需求，适用于分布式文件系统、开发测试、数据仓库以及高性能计算等场景。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1 db v"},[_c('div',{staticClass:"text"},[_vm._v("WEB应用防火墙")]),_c('div',{staticClass:"imgPart fx1 db vm"},[_vm._v(" 通过对http、https请求进行检测，识别并阻断SQL注入、跨站脚本攻击、网页木马上传、命令/代码注入、文件包含、敏感文件访问、第三方应用漏洞攻击、CC攻击、恶意爬虫扫描、跨站请求伪造等攻击，保护Web服务安全稳定 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1 db v"},[_c('div',{staticClass:"text"},[_vm._v("虚拟私有云")]),_c('div',{staticClass:"imgPart fx1 db vm"},[_vm._v(" 自定义网络划分、路由规则，配置实施立即生效，100%逻辑隔离的网络空间，支持公网VPN接入和专线接入，精确到端口的网络控制，满足金融政企的安全要求。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1 db v"},[_c('div',{staticClass:"text"},[_vm._v("云主机备份")]),_c('div',{staticClass:"imgPart fx1 db vm"},[_vm._v(" 提供简单易用的备份服务，当发生病毒入侵、人为误删除等事件时，可将数据恢复到任意备份点，为您的业务运行保驾护航。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part1 db v"},[_c('div',{staticClass:"text"},[_vm._v("对象存储（OBS）")]),_c('div',{staticClass:"imgPart fx1 db vm"},[_vm._v(" 提供海量、安全、高可靠、低成本的数据存储能力，可供您存储任意类型和大小的数据。适合备份/归档、视频点播、视频监控等多种数据存储场景。 ")])])
}]

export { render, staticRenderFns }