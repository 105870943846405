var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contBox"},[_c('div',{staticClass:"contMask"}),_c('div',{staticClass:"contVideo"},[_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"controls":false,"autoplay":"","loop":""}},[_c('source',{attrs:{"src":require("./鸟瞰.mp4"),"type":"video/mp4"}})])]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"PageEight"},[_c('div',{staticClass:"point point1",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          animate__flipOutY: !_vm.isShow,
        }},[_c('div',{staticClass:"part2"},[_vm._v("HPC算力")])]),_c('div',{staticClass:"point point2 animate__delay1",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__flipOutY animate__delay0': !_vm.isShow,
        }},[_c('div',{staticClass:"part2"},[_vm._v("云存储")])]),_c('div',{staticClass:"point point3 animate__delay2",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__flipOutY animate__delay0': !_vm.isShow,
        }},[_c('div',{staticClass:"part2"},[_vm._v("云计算")])]),_c('div',{staticClass:"point point4 animate__delay3",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__flipOutY animate__delay0': !_vm.isShow,
        }},[_c('div',{staticClass:"part2"},[_vm._v("网络安全")])]),_c('div',{staticClass:"point point5 animate__delay4",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__flipOutY animate__delay0': !_vm.isShow,
        }},[_c('div',{staticClass:"part2"},[_vm._v("中间件")])]),_c('div',{staticClass:"point point6 animate__delay5",class:{
          'animate__animated animate__fadeInUp': _vm.isShow,
          'animate__flipOutY animate__delay0': !_vm.isShow,
        }},[_c('div',{staticClass:"part2"},[_vm._v("AI数据")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }