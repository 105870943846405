var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cloud db page13"},[_vm._m(0),_c('div',{staticClass:"fx1 pr"},[_c('div',{staticClass:"center-part"},[_c('img',{staticClass:"centerImg",staticStyle:{"width":"605px"},attrs:{"src":require("../../assets/images/page13/map1.png")}}),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('dv-flyline-chart-enhanced',{staticStyle:{"width":"100%","height":"100%"},attrs:{"config":_vm.config,"dev":true}}),_vm._m(6)],1)]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cloud-left db v"},[_c('div',{staticClass:"h250 cloud-cont-bg db v"},[_c('h6',[_vm._v("产品消费统计")]),_c('div',{staticClass:"fx1"},[_c('p',{staticClass:"proAll"},[_vm._v("总收入")]),_c('div',{staticClass:"proText"},[_c('span',[_vm._v("4")]),_c('span',[_vm._v("5")]),_c('span',[_vm._v("3")]),_c('span',[_vm._v("6")]),_c('span',[_vm._v("3")]),_c('b',[_vm._v("万元")])]),_c('div',{staticClass:"proIncome db"},[_c('p',[_vm._v("近一月收入"),_c('br'),_c('b',[_vm._v("5221")]),_vm._v("万元")]),_c('p',[_vm._v("近半年收入"),_c('br'),_c('b',[_vm._v("9900")]),_vm._v("万元")]),_c('p',[_vm._v("近一年收入"),_c('br'),_c('b',[_vm._v("11900")]),_vm._v("万元")])])])]),_c('div',{staticClass:"h250 cloud-cont-bg mt10 db v"},[_c('h6',[_vm._v("产品营销趋势图")]),_c('div',{staticClass:"fx1",attrs:{"id":"market"}})]),_c('div',{staticClass:"cloud-cont-bg fx1 mt10 db v"},[_c('h6',[_vm._v("产品营销TOP5")]),_c('div',{staticClass:"fx1 db v"},[_c('ul',{staticClass:"proMarketCont"},[_c('li',[_c('div',{staticClass:"proMarket"},[_c('p',[_vm._v("云主机")]),_c('p',[_vm._v("45")])]),_c('span',[_c('b',{staticStyle:{"width":"80%"}})])]),_c('li',[_c('div',{staticClass:"proMarket"},[_c('p',[_vm._v("负载均衡")]),_c('p',[_vm._v("30")])]),_c('span',[_c('b',{staticStyle:{"width":"60%"}})])]),_c('li',[_c('div',{staticClass:"proMarket"},[_c('p',[_vm._v("对象存储")]),_c('p',[_vm._v("25")])]),_c('span',[_c('b',{staticStyle:{"width":"50%"}})])]),_c('li',[_c('div',{staticClass:"proMarket"},[_c('p',[_vm._v("云数据库Redis")]),_c('p',[_vm._v("18")])]),_c('span',[_c('b',{staticStyle:{"width":"40%"}})])]),_c('li',[_c('div',{staticClass:"proMarket"},[_c('p',[_vm._v("Web应用防火墙")]),_c('p',[_vm._v("8")])]),_c('span',[_c('b',{staticStyle:{"width":"20%"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"num1"},[_vm._v("订单成交量 "),_c('span',[_vm._v("19,900")]),_vm._v(" 笔，总额度达到 "),_c('span',[_vm._v("5,449,000.00")]),_vm._v(" 万元")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page13Line1"},[_c('div',{staticClass:"linePart1"},[_vm._v("2,900")]),_c('div',{staticClass:"linePart2"},[_vm._v("委办数量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page13Line2"},[_c('div',{staticClass:"linePart1"},[_vm._v("219")]),_c('div',{staticClass:"linePart2"},[_vm._v("云服务商数（个）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page13Line3"},[_c('div',{staticClass:"linePart1"},[_vm._v("2,781")]),_c('div',{staticClass:"linePart2"},[_vm._v("订单数量（个）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page13Line4"},[_c('div',{staticClass:"linePart1"},[_vm._v("72,781")]),_c('div',{staticClass:"linePart2"},[_vm._v("金额总数（万元）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page13IconPart"},[_c('div',{staticClass:"page13Icon1 page13Icon"},[_c('div',{staticClass:"onlyIcon"},[_vm._v("光谷云")]),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/page13/GPS-nor.png")}})])]),_c('div',{staticClass:"page13Icon2 page13Icon"},[_c('div',{staticClass:"page13IconImg"},[_c('img',{attrs:{"src":require("../../assets/images/page13/icon-poi.png")}})])]),_c('div',{staticClass:"page13Icon3 page13Icon"},[_c('div',{staticClass:"page13IconImg"},[_c('img',{attrs:{"src":require("../../assets/images/page13/icon-poi.png")}})])]),_c('div',{staticClass:"page13Icon4 page13Icon"},[_c('div',{staticClass:"page13IconImg"},[_c('img',{attrs:{"src":require("../../assets/images/page13/icon-poi.png")}})])]),_c('div',{staticClass:"page13Icon5 page13Icon"},[_c('div',{staticClass:"page13IconImg"},[_c('img',{attrs:{"src":require("../../assets/images/page13/icon-poi.png")}})])]),_c('div',{staticClass:"page13Icon6 page13Icon"},[_c('div',{staticClass:"page13IconImg"},[_c('img',{attrs:{"src":require("../../assets/images/page13/icon-poi.png")}})])]),_c('div',{staticClass:"page13Icon7 page13Icon"},[_c('div',{staticClass:"page13IconImg"},[_c('img',{attrs:{"src":require("../../assets/images/page13/icon-poi.png")}})])]),_c('div',{staticClass:"page13Icon8 page13Icon"},[_c('div',{staticClass:"page13IconImg"},[_c('img',{attrs:{"src":require("../../assets/images/page13/icon-poi.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cloud-right db v"},[_c('div',{staticClass:"h250 cloud-cont-bg db v"},[_c('h6',[_vm._v("用户数量统计")]),_c('div',{staticClass:"fx1"},[_c('div',{staticClass:"cloud-state-top cloud-state-txt userNum"},[_c('p',{staticClass:"userNumTxt"},[_vm._v("用户总数量"),_c('strong',[_vm._v("89992")]),_vm._v("个")])]),_c('div',{staticClass:"proIncome db"},[_c('p',[_c('i',[_vm._v("近一月用户增长率")]),_c('b',[_vm._v("32")]),_vm._v("%")]),_c('p',[_c('i',[_vm._v("近半年用户增长率")]),_c('b',[_vm._v("15")]),_vm._v("%")]),_c('p',[_c('i',[_vm._v("近一年用户增长率")]),_c('b',[_vm._v("12")]),_vm._v("%")])])])]),_c('div',{staticClass:"h250 cloud-cont-bg mt10 db v"},[_c('h6',[_vm._v("用户增长趋势图")]),_c('div',{staticClass:"fx1",attrs:{"id":"userAdd"}})]),_c('div',{staticClass:"cloud-cont-bg fx1 mt10 db v fit"},[_c('h6',[_vm._v("活跃用户分析")]),_c('div',{staticClass:"activeUser fx1 db v"},[_c('div',{staticClass:"cloud-til pr"},[_vm._v(" 资源类别管理 "),_c('p',[_c('span',{staticClass:"on"},[_vm._v("今日")]),_vm._v(" ｜ "),_c('span',[_vm._v("本周")]),_vm._v(" ｜ "),_c('span',[_vm._v("本月")])])]),_c('div',{staticClass:"fx1",attrs:{"id":"activeUser"}})])])])
}]

export { render, staticRenderFns }