<template>
  <div class="contBox">
    <div class="contMask"></div>
    <div class="contVideo">
      <video
          :controls="false"
          autoplay
          style="width: 100%;height: 100%;"
        >
        <source src="./001new1.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="cont">
      <div class="about db vm fx1">
        <div
          :class="
            showAnimate
              ? 'about-cont animate__animated animate__fadeInUp'
              : outFlag
              ? 'about-cont animate__animated animate__fadeOut'
              : ''
          "
        >
          <p class="scrollP">
            {{ showText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "AboutPage",
  data() {
    return {
      oldScrollTop: 0,
      scrollFlag: true,
      Intervall: null,
      timer: null,
      showText: "",
      showAnimate: false,
      outFlag: false,
    };
  },
  mounted() {
    //监听滚动事件
    this.scrollToBottom();
    setTimeout(() => {
      this.showAnimate = true;
      setTimeout(() => {
        this.init();
        setTimeout(() => {
          this.showAnimate = false;
          this.outFlag = true;
        }, 3000);
      }, 1500);
    }, 5000);
  },
  watch: {
    showText() {
      //监听滚动事件
      this.scrollToBottom();
    },
  },
  methods: {
    init() {
      const content =
        "根据东湖高新区委管委会2023年第5次常务会议精神和数字光谷总体规划，围绕建设世界一流科学城和“世界光谷”的总体目标，全区将集约化打造统一云基础设施——“光谷云”，实现集中计算存储、归集业务数据、探索数据共享等。光谷金控全资子公司——武汉光谷数字产业集团有限公司（简称“光谷数产”）在区政务和大数据局（简称“区政数局”）的指导下，启动了“光谷云”政务云、行业云板块的统筹建设工作。现为加快推进数字光谷底座建设，光谷金控（光谷数产）拟携手东湖高新区相关平台公司加快开展股权合作，通过技术与资源叠加、强强联合，设立合资公司共同拓展数字化领域业务，打造东湖高新区数字化高科技公司。此次合作以光谷数产运营团队、技术体系和服务体系为基础，以各平台公司项目和资源为支撑载体，共同开发，打造全新的数字经济样板工程，积极推动政务服务领域和企业上云服务工作。";
      const _this = this;

      this.showText = "";
      clearTimeout(this.timer);

      var speed = 5; //设置定时的速度 越来越快
      var count = 1;

      function changeContent() {
        _this.showText = content.substring(0, count); //截取字符串
        count++;

        if (count != content.length + 1) {
          // speed -= 1;
          // if (speed < 5) speed = 5;

          _this.timer = setTimeout(changeContent, speed);
        }
      }
      changeContent();
    },
    //滚动条保持最底部方法
    scrollToBottom() {
      this.$nextTick(() => {
        var container = document.querySelector(".scrollP");
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>
